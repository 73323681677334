<template>
  <div>
    <template v-if="spinShow">
      <div class="demo-spin-col">
        <Spin fix>
          <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
          <div>地图加载中</div>
        </Spin>
      </div>
    </template>
    <Tabs v-if="svgList.length > 0" v-model="defaultTab" :animated="false" @on-click="changeTabs">
      <TabPane v-for="(item, index) in svgList" :key="index" :label="stationLabel(index)" :name="'tab' + index">
        <Row :gutter="8">
          <i-col span="6">
            <div>
              <span class="m-r-10">当前已选中<b class="text-orange p-l-5 p-r-5">{{ selecteDeviceInfoList.length }}</b>项</span>
              <Button type="primary" size="small" :disabled="selecteDeviceInfoList.length === 0"
                @click="handleSetScreen">设置素材</Button>
            </div>
          </i-col>
          <i-col span="18">
            <ul style="list-style-type:none;">
              <li class="tipBox"><a href="javascrip:void(0);" style="background-color:#CCCC33;"></a>已设置(不可更改)</li>
              <li class="tipBox"><a href="javascrip:void(0);" style="background-color:#F54236"></a>可设置</li>
              <li class="tipBox"><a href="javascrip:void(0);" style="background-color:#CCCCFF"></a>在刊</li>
              <li class="tipBox"><a href="javascrip:void(0);" style="background-color:#008100"></a>可用</li>
            </ul>
          </i-col>
        </Row>

        <div style="clear:both;"></div>
        <Row>
          <i-col span="24">
            <div v-html="item.svg" name="svgStationHtml" style="height: 100vh" class="svg-station-content"
              :id="svgId + '_' + index"></div>
          </i-col>
        </Row>
      </TabPane>
    </Tabs>
    <div v-else class="default-content">
      <p style="font-size: xx-large;">抱歉，暂无SVG站点地图</p>
    </div>
  </div>
</template>

<script>
import { Message } from 'view-design'
import { svgStationMixins } from './mixins/svgStation'
import { getRimStationSvgByStationId } from '@/api/rim/stationgraph'
export default {
  mixins: [svgStationMixins],
  props: {
    stationId: {
      type: Number,
      required: true
    },
    floors: {
      type: Array,
      required: false,
      default: new Array(0)
    }
  },
  data () {
    return {
      spinShow: false,
      svgList: [],
      stationTabLables: [],
      deviceInfoList: [],
      defaultTab: 'tab0',
      isSvgLoadingFisished: false,
      buttonIcons: [],
      showButtonIcons: [],
      chooseIcon: null,
      svgId: null,
      existSvgZoom: [],
      tagDeviceIds: [],
      selecteDeviceInfoList: [],
      choiceProductType: null // 上次选择的产品类型，1：常规产品，2：电子品和语音产品
    }
  },
  methods: {
    initData () {
      this.chooseIcon = null
      this.existSvgZoom = []
      this.tagDeviceIds = []
      this.svgList = []
      this.deviceInfoList = []
      this.selecteDeviceInfoList = []
      this.choiceProductType = null
      this.spinShow = true
      this.getSvgData()
    },
    getSvgData () {
      getRimStationSvgByStationId({ stationIds: this.stationId }).then(res => {
        this.beginLoadSvgMap(res)
      }).finally(() => {
        this.$emit('load-device-info')
      })
    },
    existScreenDevice (deviceBeanList) {
      const _self = this
      _self.deviceInfoList = deviceBeanList
      const pathNodeList = document.getElementsByTagName('path')
      const gNodeList = document.getElementsByTagName('g')
      const deviceIds = _self.deviceInfoList.map(x => x.deviceId)
      _self.tagDeviceIds = _self.deviceInfoList.map(x => x.deviceId.toString())

      // 筛选可设置的设备
      const draftStatusDeviceList = _self.deviceInfoList.filter(x => x.taskStatus === -2 && x.publishWithNotSetDeviceFlag === 2)
      this.formatSvgTaglable(draftStatusDeviceList)

      if (pathNodeList.length > 0 && _self.deviceInfoList.length > 0) {
        for (let i = 0; i < pathNodeList.length; i++) {
          const el = pathNodeList[i]

          if (el.hasAttribute('data-id')) {
            // 判断灯箱点位是否包含在清单中
            const deviceId = parseInt(el.getAttribute('data-id'))
            let showTitle = ''

            if (deviceIds.indexOf(deviceId) > -1) {
              // 查找当前设备的画面信息
              const deviceScreenInfo = _self.deviceInfoList.find(x => x.deviceId === deviceId)
              switch (deviceScreenInfo.publishWithNotSetDeviceFlag) {
                case 1:
                  el.setAttribute('fill', '#CCCCFF')
                  showTitle = deviceScreenInfo.fileName
                  break
                case 2:
                  el.setAttribute('fill', deviceScreenInfo.taskStatus === -2 ? '#F54236' : '#CCCC33') // 蓝色
                  showTitle = deviceScreenInfo.waitSetFileName
                  break
              }

              el.setAttribute('cursor', 'pointer')
              // 注册点击事件，并调用父组件方法
              el.onclick = () => {
                // _self.$emit('on-select', deviceId)
                _self.handleClickDevice(deviceId, el)
              }

              // // 在路劲内部添加上刊品牌
              const textElement = this.fillPathText(showTitle, deviceId, false)
              textElement.onclick = () => {
                // _self.$emit('on-select', deviceId)
                _self.handleClickDevice(deviceId, el)
              }
              el.after(textElement)
            }
          }
        }
      }

      // 新版地图使用g
      if (gNodeList.length > 0) {
        for (let j = 0; j < gNodeList.length; j++) {
          const newEl = gNodeList[j]

          if (newEl.hasAttribute('data-id')) {
            // 判断灯箱点位是否包含在清单中
            const deviceId = parseInt(newEl.getAttribute('data-id'))
            let showTitle = ''
            if (deviceIds.indexOf(deviceId) > -1) {
              // 查找当前设备的画面信息
              const deviceScreenInfo = _self.deviceInfoList.find(x => x.deviceId === deviceId)
              newEl.setAttribute('checked', 'true')
              newEl.setAttribute('cursor', 'pointer')
              let elBBox = { x: 0, y: 0 }

              // 此处要对异性墙贴使用特殊方式处理
              if (newEl.getAttribute('data-devicemodelid') === '110') {
                for (let h = 0; h < newEl.children.length; h++) {
                  if (newEl.children[h].nodeName === 'g') { // 多个元素组，合并组合而成的墙贴
                    for (let gIndex = 0; gIndex < newEl.children[h].children.length; gIndex++) {
                      newEl.children[h].children[gIndex].setAttribute('stroke-width', '1')
                      newEl.children[h].children[gIndex].setAttribute('stroke', '#F54236')

                      if (elBBox.x === 0) {
                        if (newEl.children[h].children[gIndex].hasAttribute('x1')) {
                          elBBox = {
                            x: parseFloat(newEl.children[h].children[gIndex].getAttribute('x1')),
                            y: parseFloat(newEl.children[h].children[gIndex].getAttribute('y1'))
                          }
                        }
                      }
                    }
                  } else if (newEl.children[h].nodeName === 'text') {
                    newEl.children[h].setAttribute('fill', '#F54236') // 设置文字编号
                  } else {
                    newEl.children[h].setAttribute('stroke', '#F54236')
                    newEl.children[h].setAttribute('stroke-width', '1')

                    if (elBBox.x === 0) {
                      if (newEl.children[h].hasAttribute('x1')) {
                        elBBox = {
                          x: parseFloat(newEl.children[h].getAttribute('x1')),
                          y: parseFloat(newEl.children[h].getAttribute('y1'))
                        }
                      }
                    }
                  }
                }
              } else {
                newEl.setAttribute('fill', '#F54236') // 红色
                const gChild = newEl.children
                for (let k = 0; k < gChild.length; k++) {
                  // 文字不用呗设置为空色
                  if (gChild[k].nodeName !== 'text') {
                    // gChild[k].setAttribute('fill', '#CCCCFF') // 红色
                    switch (deviceScreenInfo.publishWithNotSetDeviceFlag) {
                      case 1:
                        gChild[k].setAttribute('fill', '#CCCCFF')
                        showTitle = deviceScreenInfo.fileName
                        break
                      case 2:
                        gChild[k].setAttribute('fill', deviceScreenInfo.taskStatus === -2 ? '#F54236' : '#CCCC33') // 蓝色
                        showTitle = deviceScreenInfo.waitSetFileName
                        break
                    }
                  }

                  // 找到第一个元素位置信息， 用于定位文字信息
                  if (elBBox.x === 0) {
                    if (gChild[k].hasAttribute('x1')) {
                      elBBox = {
                        x: parseFloat(gChild[k].getAttribute('x1')),
                        y: parseFloat(gChild[k].getAttribute('y1'))
                      }
                    } else if (gChild[k].hasAttribute('x')) {
                      elBBox = {
                        x: parseFloat(gChild[k].getAttribute('x')),
                        y: parseFloat(gChild[k].getAttribute('y'))
                      }
                    } else if (gChild[k].nodeName === 'path') {
                      const pathBox = gChild[k].getAttribute('d').split('m')[1].split('c')[0].split(',')
                      elBBox = {
                        x: parseFloat(pathBox[0]),
                        y: parseFloat(pathBox[1])
                      }
                    }
                  }
                }
              }

              // 注册点击事件，并调用父组件方法
              newEl.onclick = () => {
                // _self.$emit('on-select', deviceId)
                _self.handleClickDevice(deviceId, newEl, true)
              }

              // 在路劲内部添加上刊品牌
              // const demandEntity = resourceBeanList.find(x => x.deviceId === deviceId)
              newEl.appendChild(this.fillPathText(showTitle, deviceId, false, true, elBBox))
            }
          }
        }
      }

      // 结束加载动画
      this.spinShow = false
    },
    fillPathText (screenName, deviceId, isClick = true, isNewMap = false, elBBox = null) {
      const text = document.createElementNS('http://www.w3.org/2000/svg', 'text')
      // 创建一个text，并添加到path后面
      text.setAttribute('fill', '#333')
      text.setAttribute('cursor', 'pointer')
      text.setAttribute('x', isNewMap ? elBBox.x : 0)
      if (this.$store.getters.token.userInfo.publisherId === 13) { // 青岛地铁的地图样式和厦门不一样
        text.setAttribute('font-size', isNewMap ? '4' : '8')
        text.setAttribute('y', isNewMap ? elBBox.y + 5 : 7)
      } else {
        text.setAttribute('font-size', isNewMap ? '4' : '14')
        text.setAttribute('y', isNewMap ? elBBox.y + 8 : 10)
      }
      text.textContent = screenName.substr(0, 6)
      // text.onclick = () => {
      //   if (isClick) {
      //     _self.bindClickStationBrand(deviceId)
      //   } else {
      //     _self.$emit('on-select', deviceId)
      //   }
      // }

      return text
    },
    /**
     * 设备点击事件
     * @param {number} deviceId 设备编号
     */
    handleClickDevice (deviceId, element, newMap = false) {
      const deviceInfo = this.deviceInfoList.find(x => x.deviceId === deviceId)
      if (deviceInfo) {
        if (deviceInfo.publishWithNotSetDeviceFlag === 2 && deviceInfo.taskStatus === -2) {
          if (deviceInfo.waitSetTaskitemFileList.length) {
            // 已设置过素材
            this.$emit('on-select', deviceInfo)
          } else {
            // 带设置设备且为“草稿”状态，进行设备块的多选操作
            const index = this.selecteDeviceInfoList.findIndex(x => x.deviceId === deviceId)
            if (index === -1) {
              let productType = 1
              // 电子屏和语音产品不能和其他产品一起勾选设置
              if ([155, 409, 411, 413, 414, 415, 416, 417, 418, 419, 421, 422, 423, 424, 425].includes(deviceInfo.productId)) {
                productType = 2
              }
              // 当前选择设备所属产品类型和之前产品类型不一致，则不加入
              if (this.choiceProductType && this.choiceProductType !== productType) {
                Message.error('当前选择资源产品类型和之前选择的不一致，无法进行批量操作！')
                return false
              }
              this.choiceProductType = productType

              // 未被选择则进行加入
              this.selecteDeviceInfoList.push(deviceInfo)
              if (newMap) {
                const gChild = element.children
                for (let i = 0; i < gChild.length; i++) {
                  if (gChild[i].nodeName !== 'text') {
                    gChild[i].setAttribute('fill', '#FFCC33')
                  }
                }
              } else {
                element.setAttribute('fill', '#FFCC33')
              }
            } else {
              // 已选择则进行删除
              this.selecteDeviceInfoList.splice(index, 1)
              if (newMap) {
                const gChild = element.children
                for (let i = 0; i < gChild.length; i++) {
                  if (gChild[i].nodeName !== 'text') {
                    gChild[i].setAttribute('fill', '#F54236')
                  }
                }
              } else {
                element.setAttribute('fill', '#F54236')
              }
            }
          }
        } else {
          this.$emit('on-select', deviceInfo)
        }
      }
    },
    handleSetScreen () {
      this.$emit('on-batch-set', this.selecteDeviceInfoList)
    }
  }
}
</script>

<style lang="less" scoped>
.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}

@keyframes ani-demo-spin {
  from {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.demo-spin-col {
  height: 100vh;
  position: relative;
}
</style>
