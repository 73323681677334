import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 查询任务中包含的站点集合
 * @param {Object} data
 * @returns
 */
export function getTaskStationList (data) {
  return request.post('/ooh-msp/v1/demand-svg/gettaskstationlist', qs.stringify(data))
}

/**
 * 查询站点指定楼层点位的在刊画面信息
 * @param {Object} data
 * @returns
 */
export function getStationPublishingDeviceList (data) {
  return request.post('/ooh-msp/v1/demand-svg/getstationpublishingdevicelist', qs.stringify(data))
}
