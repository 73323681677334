import svgPanZoom from 'svg-pan-zoom'
export const svgStationMixins = {
  methods: {
    beginLoadSvgMap (svgMap) {
      this.isSvgLoadingFisished = false
      this.stationTabLables = []
      if (this.floors.length && svgMap.length) {
        this.svgList = svgMap.filter(s => this.floors.includes(s.floor))
      } else {
        this.svgList = svgMap
      }
      let label

      // 判断是否为换乘站
      const resultarr = [...new Set(this.svgList.map(x => x.stationId))]

      for (let index = 0; index < this.svgList.length; index++) {
        label = (this.svgList[index].floor > 0) ? '地上' : '地下'
        label += this.svgList[index].floor + '层' + this.svgList[index].positionName

        if (resultarr.length > 1) {
          label = this.svgList[index].assetName.substr(this.svgList[index].assetName.indexOf('铁') + 1) + ' ' + label
        }

        this.stationTabLables.push({
          stationName: this.svgList[index].stationName,
          label: label,
          quantity: 0
        })
      }

      if (this.svgList.length > 0) {
        this.changeTabs('tab0')
      } else {
        this.isSvgLoadingFisished = true
      }
      // this.svgLoadingFisished()
    },
    beginSvgPanZoom (svgId) {
      if (this.existSvgZoom.indexOf(svgId) > -1) {
        return
      } else {
        this.existSvgZoom.push(svgId)
      }

      this.$nextTick(() => {
        const currentSvgElement = document.getElementById(svgId).firstElementChild
        // 设置svg呈现在画板上的尺寸
        currentSvgElement.setAttribute('width', '100%')
        currentSvgElement.setAttribute('height', '100%')

        var curMap = svgPanZoom(currentSvgElement, {
          panEnabled: true,
          controlIconsEnabled: false,
          zoomEnabled: true,
          dblClickZoomEnabled: true,
          center: true
        }).zoom(1)

        // 仅限新版SVG，需要添加鼠标移入移除事件
        if (currentSvgElement.id === '') {
          curMap.zoom(1)
          this.addMapMouseEvent(currentSvgElement)
        }
      })
    },
    changeTabs (name) {
      this.defaultTab = name
      const currentSvgId = this.svgId + '_' + name.substr(3)
      this.beginSvgPanZoom(currentSvgId)

      // 确认需要显示的ICON
      this.showButtonIcons = []
      this.chooseIcon = null
      let newEl = null

      this.$nextTick(() => {
        const gNodeList = document.getElementById(currentSvgId).getElementsByTagName('g')
        if (gNodeList.length > 0) {
          for (let j = 0; j < gNodeList.length; j++) {
            newEl = gNodeList[j]

            if (newEl.hasAttribute('data-devicemodelid')) {
              // 双面电子屏, 同时不包含301的电子屏
              if (newEl.getAttribute('data-devicemodelid') === '306') {
                if (!this.showButtonIcons.find(x => x.deviceModels === '301')) {
                  this.showButtonIcons.push(
                    this.buttonIcons.find(x => x.deviceModels === '301')
                  )
                }
              } else if (!this.showButtonIcons.find(x => x.deviceModels === newEl.getAttribute('data-devicemodelid')) &&
                this.buttonIcons.find(x => x.deviceModels === newEl.getAttribute('data-devicemodelid'))) {
                this.showButtonIcons.push(
                  this.buttonIcons.find(x => x.deviceModels === newEl.getAttribute('data-devicemodelid'))
                )
              }
            }
          }
        }

        // 切换显示出媒体编号
        // this.changeShowType()
      })
    },
    formatSvgTaglable (resourceBeanList) {
      const floorStatistic = []
      const distinctResource = []
      let floorStatisticBean

      resourceBeanList.forEach(element => {
        if (distinctResource.indexOf(element.deviceId) === -1) {
          distinctResource.push(element.deviceId)
          floorStatisticBean = floorStatistic.find(x => x.stationId === element.stationId && x.floor === element.floor)
          if (floorStatisticBean) {
            floorStatisticBean.quantity++
          } else {
            floorStatistic.push({
              stationId: element.stationId,
              floor: element.floor,
              quantity: 1
            })
          }
        }
      })

      // 开始重新格式化Tab标签
      let index = 0
      let floorStatisticBySvgHtml
      this.svgList.forEach(svgHtml => {
        floorStatisticBySvgHtml = floorStatistic.find(x => x.stationId === svgHtml.stationId && x.floor === svgHtml.floor)
        if (floorStatisticBySvgHtml) {
          this.stationTabLables[index].quantity = floorStatisticBySvgHtml.quantity
          if (this.defaultTab === 'tab0' && this.stationTabLables[0].quantity === 0 && floorStatisticBySvgHtml.quantity > 0) {
            this.defaultTab = 'tab' + index
            this.beginSvgPanZoom(this.svgId + '_' + index)
            this.changeTabs(this.defaultTab)
          }
        }
        index++
      })

      this.stationTabLables = Object.assign({}, this.stationTabLables)
    },
    stationLabel (index) {
      return (h) => {
        return h('div', [
          h('span', this.stationTabLables[index].label),
          h('Badge', {
            style: {
              left: '5px',
              top: '-10px'
            },
            props: {
              count: this.stationTabLables[index].quantity
            }
          })
        ])
      }
    },
    addMapMouseEvent (svgElement) { // 添加普通地图的事件
      const svgChidrenElement = svgElement.lastElementChild.lastElementChild.children
      const _self = this

      if (svgChidrenElement) {
        for (let index = 0; index < svgElement.lastElementChild.children.length; index++) {
          // 移除title标签， 改标签用于tips
          if (svgElement.lastElementChild.children[index].nodeName === 'title') {
            svgElement.lastElementChild.removeChild(svgElement.lastElementChild.children[index])
          } else if (svgElement.lastElementChild.children[index].children[0].nodeName === 'title') {
            svgElement.lastElementChild.children[index].removeChild(svgElement.lastElementChild.children[index].children[0])
          }
        }

        for (let index = 0; index < svgChidrenElement.length; index++) {
          if (svgChidrenElement[index].nodeName === 'g' && svgChidrenElement[index].hasAttribute('data-id')) {
            // 为媒体元素添加鼠标事件
            const curElement = svgChidrenElement[index]
            const xOffset = 15
            const yOffset = 15
            // 某些地图显示方式不需要有鼠标点击事件，可在需要点击的地方添加此鼠标样式
            // curElement.setAttribute('cursor', 'pointer')

            // 将所有的墙贴边框线条加粗，不然很难 被选中 和 区分底图
            // if (curElement.getAttribute('data-devicemodelid') === '110') {
            //   for (let h = 0; h < curElement.children.length; h++) {
            //     // 异性墙贴可能包含有多个G组成的内容, 循环检查G标签内的元素
            //     if (curElement.children[h].nodeName === 'g') {
            //       for (let gIndex = 0; gIndex < curElement.children[h].children.length; gIndex++) {
            //         curElement.children[h].children[gIndex].setAttribute('stroke-width', '2')
            //         // curElement.children[h].children[gIndex].setAttribute('stroke', '#231814')
            //       }
            //     } else {
            //       curElement.children[h].setAttribute('stroke-width', '2')
            //     }
            //   }
            // }

            curElement.addEventListener('mouseover', (e) => {
              for (let i = 0; i < curElement.children.length; i++) {
                if (curElement.children[i].nodeName === 'g') {
                  // 异性墙贴可能包含有多个G组成的内容, 循环检查G标签内的元素
                  for (let gIndex = 0; gIndex < curElement.children[i].children.length; gIndex++) {
                    curElement.children[i].children[gIndex].setAttribute('stroke', '#19be6b') // 绿色
                  }
                } else if (curElement.children[i].nodeName !== 'text' && curElement.children[i].hasAttribute('stroke') && curElement.children[i].getAttribute('stroke') !== 'null') {
                  curElement.children[i].setAttribute('stroke', '#19be6b')
                }
              }

              var divTemp = document.createElement('div')
              var nodes = null
              // 文档片段，一次性append，提高性能
              var fragment = document.createDocumentFragment()
              divTemp.innerHTML = "<div id='screenshot'><p>" + curElement.getAttribute('data-devicemodel') + '</p><p>' + curElement.getAttribute('data-devicecode') + '</p></div>'
              nodes = divTemp.childNodes
              for (var i = 0, length = nodes.length; i < length; i += 1) {
                fragment.appendChild(nodes[i].cloneNode(true))
              }
              svgElement.parentNode.appendChild(fragment)
              // 据说下面这样子世界会更清净
              nodes = null
              fragment = null

              document.getElementById('screenshot').style.top = (e.offsetY + yOffset) + 'px'
              document.getElementById('screenshot').style.left = (e.offsetX + xOffset) + 'px'
            })
            curElement.addEventListener('mouseout', (e) => {
              for (let j = 0; j < curElement.children.length; j++) {
                if (curElement.getAttribute('data-devicemodelid') === '110') {
                  if (curElement.children[j].nodeName === 'g') {
                    // 异性墙贴可能包含有多个G组成的内容, 循环检查G标签内的元素
                    for (let gIndex = 0; gIndex < curElement.children[j].children.length; gIndex++) {
                      curElement.children[j].children[gIndex].setAttribute('stroke', _self.tagDeviceIds.includes(curElement.getAttribute('data-id')) ? '#F54236' : '#231814')
                    }
                  } else if (curElement.children[j].nodeName !== 'text') {
                    curElement.children[j].setAttribute('stroke', _self.tagDeviceIds.includes(curElement.getAttribute('data-id')) ? '#F54236' : '#231814')
                  }
                } else if (curElement.children[j].nodeName !== 'text' && curElement.children[j].hasAttribute('stroke') && curElement.children[j].getAttribute('stroke') !== 'null') {
                  curElement.children[j].setAttribute('stroke', '#000000')
                }
              }

              document.getElementById('screenshot').parentNode.removeChild(document.getElementById('screenshot'))
            })
            curElement.addEventListener('mousemove', (e) => {
              document.getElementById('screenshot').style.top = (e.offsetY + yOffset) + 'px'
              document.getElementById('screenshot').style.left = (e.offsetX + xOffset) + 'px'
            })
          } else if (svgChidrenElement[index].nodeName === 'title') {
            svgElement.lastElementChild.lastElementChild.removeChild(svgChidrenElement[index])
          }
        }
      }
    }
  }
}
