import request from '@/utils/requestV2'
const qs = require('qs')

// 获取是否含3D的图
export function check3d (data) {
  return request({
    url: '/ooh-rim/v1/stationgraph/check3d',
    method: 'POST',
    data: qs.stringify(data)
  })
}

/**
 * 获取设备的svg图
 * @param {Object} data
 * @returns
 */
export function getDeviceSvg (data) {
  return request.post('/ooh-rim/v1/stationgraph/getdevicesvg', qs.stringify(data))
}

/**
 * 通过站点ID获取所属的SVG信息
 * @param {Object} data
 * @returns
 */
export function getRimStationSvgByStationId (data) {
  return request({
    url: '/ooh-rim/v1/stationgraph/getStationSvgByStationId',
    method: 'POST',
    data: qs.stringify(data),
    timeout: 200000
  })
}
